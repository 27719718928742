<template>
    <main>
        <HeaderTab :title="$t('global.gestion_vehicule')" />

        <div id="content">
			<div class="container-fluid">
				<div class="row">
					<div class="col-12">
						<div class="box">
							<CustomTable
								ref="table"
								id_table="vehicule"
								:busy.sync="table_busy"
								primaryKey="vehicule_id"
								:hide_if_empty="true"
								:items="vehicules"
								:externSlotColumns="['vehicule_type']"
							>
								<template v-slot:[`custom-slot-cell(vehicule_type)`]="{ data }">
									{{ $t('vehicule.'+data.vehicule_type) }}
								</template>
							</CustomTable>
                		</div>
                	</div>
                </div>
            </div>
        </div>

         <b-modal ref="modal" hide-footer>
            <template v-slot:modal-title>
                <template v-if="vehicule_edit.vehicule_id">
                    {{ $t("vehicule.modifier") }}
                </template>

                <template v-else>
                    {{ $t("vehicule.ajouter") }}
                </template>
            </template>
           
           <AddVehicule :vehicule="vehicule_edit" @submit="vehiculeSubmit"/>
        </b-modal>
    </main>
</template>

<script type="text/javascript">
import Deplacement from "@/mixins/Deplacement.js"
import Navigation from "@/mixins/Navigation.js"

export default {
	name: 'VehiculeManagement',
	mixins: [Deplacement, Navigation],
	data () {
		return {
			table_busy: false,
			vehicules: [],
			events_tab: {
				'TableAction::goToAddVehicule': this.openModalAddVehicule,
				'TableAction::goToEditVehicule': this.openModalEditVehicule,
				'TableAction::goToArchiveVehicule': this.goToArchiveVehicule,
			},
			vehicule_edit: {}
		}
	},

	mounted() {
		this.init_component()
	},

	methods: {
		async init_component() {
			this.table_busy = true
			this.vehicules = await this.getAllVehicules()
			this.table_busy = false
		},

		openModalAddVehicule() {
			this.vehicule_edit = {}
			this.$refs.modal.show()
		},

		openModalEditVehicule(vehicule) {
			this.vehicule_edit = vehicule
			this.$refs.modal.show()
		},

		vehiculeSubmit() {
			this.vehicule_edit = {}
			this.$refs.modal.hide()
			this.init_component()
		},

		async goToArchiveVehicule(vehicule) {
			await this.archiveOrUnarchiveVehicule(vehicule.vehicule_id)
			this.$refs.table.refreshTable()
			this.init_component()
		}
	},

	components: {
		HeaderTab: () => import('@/components/HeaderTab'),
		CustomTable : () => import('GroomyRoot/components/Table/CustomTable'),
		AddVehicule: () => import('@/components/Mouvements/AddVehicule')
	}
}

</script>